import React, { createContext, useContext, useState } from 'react'

const contextDefaultValues = {
  alert: {
    show: false,
    message: '',
    type: 'success',
  },
  setAlert: (data) => data,
}

const AlertContext = createContext(contextDefaultValues)

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState(contextDefaultValues.alert)

  return (
    <AlertContext.Provider
      value={{
        alert,
        setAlert,
      }}
    >
      {children}
    </AlertContext.Provider>
  )
}

export default () => useContext(AlertContext)
