// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-btn-primary {
  background-color: #008ecc;
}

.otp-blocks {
  width: auto !important;
  height: auto !important;
  font-size: 20px;
  max-width: 13%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, .3);
}
.custom-loader {
  height: 100vh;
  place-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  background-color: #0c0c0c1f;
  width: 100%;
  z-index: 9999;
}
::-webkit-scrollbar {
  width: 4px;
  background: #2835879e;
}
::-webkit-scrollbar-thumb {
  background: #008ecc;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;;EAEA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;EACvB,eAAe;EACf,cAAc;EACd,kBAAkB;EAClB,mCAAmC;AACrC;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,eAAe;EACf,2BAA2B;EAC3B,WAAW;EACX,aAAa;AACf;AACA;EACE,UAAU;EACV,qBAAqB;AACvB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n.ant-btn-primary {\n  background-color: #008ecc;\n}\n\n.otp-blocks {\n  width: auto !important;\n  height: auto !important;\n  font-size: 20px;\n  max-width: 13%;\n  border-radius: 4px;\n  border: 1px solid rgba(0, 0, 0, .3);\n}\n.custom-loader {\n  height: 100vh;\n  place-items: center;\n  display: flex;\n  justify-content: center;\n  position: fixed;\n  background-color: #0c0c0c1f;\n  width: 100%;\n  z-index: 9999;\n}\n::-webkit-scrollbar {\n  width: 4px;\n  background: #2835879e;\n}\n::-webkit-scrollbar-thumb {\n  background: #008ecc;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
