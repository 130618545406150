import React, { useState } from "react";
import { Row, Col, Pagination } from "antd";
import { DeleteOutlined, WeiboOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import {
  ListSection,
  Info,
  PaginationLayout,
  ListLayout,
  DeleteBtn,
  Infosubheading
} from "styles/pages/Devices";
import useDelete from "hooks/useDelete";
import { DELETE_ONBOARDED_DEVICE } from "constants/api";

const SelectedDevices = ({
  deviceList,
  currentPage,
  onPageChange,
  totalDeviceCount,
}) => {
  const { mutateAsync: deleteAllDevices } = useDelete()
  const [selectedDeviceId, setSelectedDeviceId] = useState([]);

  const handleDeviceId = (e, deviceId) => {
    if (e.target.checked) {
      setSelectedDeviceId([...selectedDeviceId, deviceId])
    } else {
      const removeDevice = selectedDeviceId.filter((device) => device !== deviceId)
      setSelectedDeviceId(removeDevice)
    }
  }

  const deleteDevices = () => {
    const account_number = localStorage.getItem('account_number')
    selectedDeviceId.map((deviceId) => {
      const DELETE_URL = `${DELETE_ONBOARDED_DEVICE}${deviceId}/?resource=jio:jttms:${account_number}:device:&action=delete_device`
      deleteAllDevices({
        url: DELETE_URL,
        token: true
      }).then((res) => {
        setSelectedDeviceId([])
        onPageChange(1, 20)
        toast.success(res.response.message)
      }).catch((error) => {
        console.error(error)
      });
    })
  }

  return (
    <div>
      <div style={{ textAlign: 'right' }}><DeleteBtn disabled={selectedDeviceId.length == 0} onClick={deleteDevices}><DeleteOutlined style={{ marginRight: "10px" }} />Delete</DeleteBtn></div>
      {deviceList.length > 0 && (
        <ListLayout>
          {deviceList.map((device, index) => {
            return (
              <ListSection key={index}>
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <WeiboOutlined
                          style={{
                            fontSize: "30px",
                            marginTop: "15px",
                            marginRight: "10px",
                          }}
                        />
                      </Col>
                      <Col>
                        <Info>Name: {device.name}</Info>
                        <Infosubheading>{(device.device_type).split('_').join(' ').replace(/\b\w/g, (char) => char.toUpperCase())}</Infosubheading>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="input-type-checkbox" style={{ paddingLeft: "40px", verticalAlign: "middle", alignSelf: "center" }}>
                    <input checked={!!selectedDeviceId.find((deviceId) => device.device_id == deviceId)?.length} onChange={(e) => handleDeviceId(e, device.device_id)} style={{ width: "32px", accentColor: "#5a50c1", height: "23px" }} type="checkbox" />
                  </Col>
                </Row>
              </ListSection>
            );
          })}
        </ListLayout>
      )}
      {deviceList.length > 0 &&
        <PaginationLayout>
          <Pagination
            defaultCurrent={1}
            current={currentPage}
            pageSize={20}
            onChange={onPageChange}
            total={totalDeviceCount}
          />
        </PaginationLayout>
      }
    </div>
  );
};

export default SelectedDevices;
