import React, { useState } from 'react'
import { Input, Button, Label } from 'styles/pages/LandingPage'
import usePost from 'hooks/usePost';
import { VALIDATE_SIGNUP_OTP } from 'constants/api';
import { toast } from 'react-toastify';
import useMetaData from 'context/metaData'

const ValidateOtp = ({next}) => {
    const { registerPhoneNumber, setregisterUserToken } = useMetaData();
    const {mutateAsync: validateOtp} = usePost();
    const [otp, setOtp] = useState('');
    const [disableOtp, setDisableOtp] = useState(true);

    const handleChange = (e) => {
        let regex = /^\d+$/;

        if ((regex.test(e.target.value) || e.target.value == '') && e.target.value.length <= 8) {
            setOtp(e.target.value);
            if (otp.length > 3) {
                setDisableOtp(false);
            } else {
                setDisableOtp(true);
            }
        }
    };

    const verifyOtp = () => {
        const payload = {
            username: registerPhoneNumber,
            "otp": otp,
            "otp_type": "signup"
        };
        validateOtp({ url: VALIDATE_SIGNUP_OTP, payload }).then((res) => {
            toast.success(res?.response?.message);
            setregisterUserToken(res?.response?.register_token)
            next();
        }).catch((error) => {
            console.error(error)
        });
    }
    return (
        <>
            <Label>OTP</Label>
            <Input
                type="password"
                name="otp"
                value={otp}
                onChange={handleChange}
            />
            <Button disabled={disableOtp} onClick={verifyOtp} type="submit">
                Verify
            </Button>
        </>
    )
}

export default ValidateOtp