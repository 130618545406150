import React, { useEffect, useState } from "react";
import { EditOutlined, PhoneOutlined, UserOutlined, TeamOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Row } from "antd";
import { toast } from 'react-toastify';
import { Select } from "antd";
import { Link } from "react-router-dom";
import {
  ImageBox,
  ProfileSection,
  SubHeading,
  SubHeadingText,
  Title,
  ProfileImage,
  ItemContent,
  EditIcon,
  InputText,
  UpdateButton,
} from "styles/pages/Profile";
import usePut from "hooks/usePut";
import useGet from "hooks/useGet";
import EmailIcon from 'assets/images/email.png';
// import usePost from "hooks/usePost";
import {
  GET_PROFILE_PIC_UPLOAD_URL,
  GET_PROFILE_PIC_DOWNLOAD_URL,
  UPDATE_PROFILE,
} from "constants/api";

const ProfilePage = ({ userDetails, setItemsPath, setSelectedKey }) => {
  const { mutateAsync: getProfileUrl } = usePut();
  // const { mutateAsync: updateProfilePic } = usePost();
  const { mutateAsync: getProfilePicDownloadUrl } = useGet();
  // const [profilePicTempUrl, setProfilePicTempUrl] = useState("");
  // const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState("");
  // const [previewTitle, setPreviewTitle] = useState("");
  // const [imageBinaryData, setimageBinaryData] = useState();
  // const [fileList, setFileList] = useState([]);
  // const [showSaveButton, setShowSaveButton] = useState(false);
  const [details, setDetails] = useState({});

  useEffect(() => {
    const path = [
      {
        title: <Link to="/dashboard">Dasboard</Link>,
      },
      {
        title: <Link to="/profile">Profile</Link>,
      },
    ];
    setItemsPath(path);
    setSelectedKey(4);
  }, []);

  useEffect(() => {
    setDetails({
      name: userDetails?.account?.name,
      gender: userDetails?.user_details?.gender,
      dob: userDetails?.user_details?.dob,
      phone_number: userDetails?.phone_number,
      alternate_phone_number: userDetails?.alternate_phone_number,
      email: userDetails?.email,
    });
  }, [userDetails]);
  const inputs = {
    name: true,
    gender: true,
    dob: true,
    phone_number: true,
    alternate_phone_number: true,
  };
  const [disableInput, setDisableInput] = useState(inputs);
  const [updateBtn, setUpdateBtn] = useState(true);

  // const getBase64 = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };

  useEffect(() => {
    const account_number = localStorage.getItem("account_number");
    const payload = {
      resource: `jio:aaa:${account_number}:accounts:`,
      action: "upload_profile_pic",
      image_type: "jpeg",
    };
    getProfileUrl({
      url: GET_PROFILE_PIC_UPLOAD_URL,
      payload,
      token: true,
    }).then(() => {
      // setProfilePicTempUrl(res?.response?.payload?.profile_pic_temp_url);
    }).catch((error) => {
      console.error(error)
    });
  }, []);

  const getProfileDetails = () => {
    const account_number = localStorage.getItem("account_number");
    const URL = `${GET_PROFILE_PIC_DOWNLOAD_URL}${account_number}:accounts:&action=download_profile_pic`;
    getProfilePicDownloadUrl({
      url: URL,
      token: true,
    }).then((res) => {
      if (res.response.payload.profile_pic_temp_url !== "") {
        // setFileList([{
        //   uid: '1',
        //   name: 'example2.jpg',
        //   status: 'done',
        //   url: res.response.payload.profile_pic_temp_url,
        // }])
      }
    }).catch((error) => {
      console.error(error)
    });
  }

  useEffect(() => {
    getProfileDetails();
  }, []);

  // const handleChange = ({ fileList: newFileList }) => {
  //   setFileList(newFileList);
  //   setShowSaveButton(true);
  // };

  // const handlePreview = async (file) => {
  //   if (!file.url && !file.preview) {
  //     file.preview = await getBase64(file.originFileObj);
  //   }
  //   setPreviewImage(file.url || file.preview);
  //   setPreviewOpen(true);
  //   setPreviewTitle(
  //     file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
  //   );
  // };

  // const handleCancel = () => setPreviewOpen(false);

  // const handleBeforeUpload = (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();

  //     reader.onload = () => {
  //       const binaryData = reader.result;
  //       setimageBinaryData(binaryData);
  //       resolve();
  //     };

  //     reader.onerror = (error) => {
  //       reject(error);
  //     };

  //     reader.readAsBinaryString(file);
  //   });
  // };

  // const saveProfileImage = async () => {
  //   setShowSaveButton(false);
  //   getProfileUrl({
  //     url: profilePicTempUrl,
  //     type: "custom",
  //     file: true,
  //     payload: imageBinaryData,
  //   }).then((res) => {
  //     toast.success(res?.response?.message)
  //   }).catch((error) => {
  //     console.error(error)
  //   });
  // };

  const handleDisableInput = (input) => {
    setDisableInput({ ...inputs, [input]: false });
  };

  const handleDetailsChange = (e) => {
    if (
      e.target.name == "phone_number" ||
      e.target.name == "alternate_phone_number"
    ) {
      let phone = /^\d+$/;
      if (phone.test(e.target.value)) {
        setDetails({ ...details, [e.target.name]: e.target.value });
        setUpdateBtn(false);
      }
    } else {
      setDetails({ ...details, [e.target.name]: e.target.value });
    }
  };

  const handleGenderChange = (value) => {
    setUpdateBtn(false);
    setDetails({ ...details, gender: value });
  };

  const updateProfile = () => {
    setUpdateBtn(true);
    const account_number = localStorage.getItem("account_number");
    const payload = {
      resource: `jio:aaa:${account_number}:accounts:`,
      action: `update_account_details`,
      alternate_phone_number: details.alternate_phone_number,
      phone_number: details.phone_number,
      email: details.email,
      user_details: {
        gender: details.gender,
        dob: details.dob,
      },
    };
    getProfileUrl({
      url: UPDATE_PROFILE,
      token: true,
      payload,
    }).then((response) => {
      toast.success(response?.response?.message)
    }).catch((error) => {
      console.error(error)
    });
  };

  return (
    <ProfileSection>
      <ProfileImage className="profile-img">
        <span className="edit-icon"></span>
        {/* <Upload
          beforeUpload={handleBeforeUpload}
          listType="picture-circle"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
        >
          {fileList.length > 0 ? null : (
            <div style={{ position: "relative" }}>
              <div
                style={{
                  position: "absolute",
                  right: "-15px",
                  cursor: "pointer",
                  top: "0px",
                  color: "#fff",
                }}
              >
                <EditOutlined />
              </div> */}
              <Avatar size={80} icon={<UserOutlined />} />
            {/* </div>
          )}
        </Upload> */}
        {/* {showSaveButton && fileList.length == 1 ? (
          <Button type="primary" onClick={saveProfileImage}>
            Save
          </Button>
        ) : null} */}
        {/* <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img
            alt="example"
            style={{
              width: "100%",
            }}
            src={previewImage}
          />
        </Modal> */}
        <h2>{userDetails?.account?.name || ""}</h2>
      </ProfileImage>
      <Title>Personal Information</Title>
      <Row style={{ marginTop: "10px" }} gutter={16}>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={24} xl={24}>
          <Card>
            <ItemContent>
              <ImageBox>
                <TeamOutlined />
              </ImageBox>
              <span>
                <SubHeading>Gender</SubHeading>
                <SubHeadingText>
                  <Select
                    value={details?.gender || ""}
                    style={{
                      width: "216px",
                    }}
                    disabled={disableInput.gender}
                    onChange={handleGenderChange}
                    options={[
                      {
                        value: "male",
                        label: "Male",
                      },
                      {
                        value: "female",
                        label: "Female",
                      },
                    ]}
                  />
                </SubHeadingText>
              </span>
            </ItemContent>
            <EditIcon>
              <EditOutlined onClick={() => handleDisableInput("gender")} />
            </EditIcon>
          </Card>
        </Col>
        {/* <Col className="gutter-row" xs={24} sm={24} md={12} lg={24} xl={24}>
          <Card>
            <ItemContent>
              <ImageBox>
                <CalendarFilled />
              </ImageBox>
              <span>
                <SubHeading>Date of birth</SubHeading>
                <SubHeadingText>
                  <InputText
                    value={details?.dob || ""}
                    name="dob"
                    type="date"
                    onChange={(e) => handleDetailsChange(e)}
                    disabled={disableInput.dob}
                  />
                </SubHeadingText>
              </span>
            </ItemContent>
            <EditIcon>
              <EditOutlined onClick={() => handleDisableInput("dob")} />
            </EditIcon>
          </Card>
        </Col> */}

        <Col className="gutter-row" xs={24} sm={24} md={12} lg={24} xl={24}>
          <Card>
            <ItemContent>
              <ImageBox>
                <PhoneOutlined />
              </ImageBox>
              <span>
                <SubHeading>Phone Number</SubHeading>
                <SubHeadingText>
                  <InputText
                    readOnly
                    value={details?.phone_number || ""}
                    name="phone_number"
                    onChange={(e) => handleDetailsChange(e)}
                    disabled={disableInput.phone_number}
                  />
                  {/* {userDetails?.phone_number || ""} */}
                </SubHeadingText>
              </span>
            </ItemContent>
            {/* <EditIcon>
              <EditOutlined
                onClick={() => handleDisableInput("phone_number")}
              />
            </EditIcon> */}
          </Card>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={24} xl={24}>
          <Card>
            <ItemContent>
              <ImageBox>
                <PhoneOutlined />
              </ImageBox>
              <span>
                <SubHeading>Alternate Phone Number</SubHeading>
                <SubHeadingText>
                  <InputText
                    value={details?.alternate_phone_number || ""}
                    name="alternate_phone_number"
                    readOnly
                    onChange={(e) => handleDetailsChange(e)}
                    disabled={disableInput.alternate_phone_number}
                  />
                  {/* {userDetails?.alternate_phone_number || ""} */}
                </SubHeadingText>
              </span>
              {/* <EditIcon>
                <EditOutlined
                  onClick={() => handleDisableInput("alternate_phone_number")}
                />
              </EditIcon> */}
            </ItemContent>
          </Card>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={12} lg={24} xl={24}>
          <Card>
            <ItemContent>
              <ImageBox>
                <img src={EmailIcon} width={23} />
              </ImageBox>
              <span>
                <SubHeading>Email</SubHeading>
                <SubHeadingText>
                  <InputText
                    value={details?.email || ""}
                    name="email"
                    readOnly
                    onChange={(e) => handleDetailsChange(e)}
                    disabled
                  />
                  {/* {userDetails?.email || ""} */}
                </SubHeadingText>
              </span>
            </ItemContent>
            {/* <EditIcon>
              <EditOutlined />
            </EditIcon> */}
          </Card>
        </Col>
      </Row>
      <UpdateButton disabled={updateBtn} onClick={updateProfile}>
        Update
      </UpdateButton>
    </ProfileSection>
  );
};

export default ProfilePage;
