import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from 'leaflet';
import MarkerIcon from "assets/images/marker.png"

const MapLayout = ({deviceLocations}) => {
    const locationMarker = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        tooltipAnchor: [16, -28]
      });
  return (
    <MapContainer center={[deviceLocations[0].lat, deviceLocations[0].long]} zoom={13} attributionControl={false} style={{ height: '100%' }}>
    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={[deviceLocations[0].lat, deviceLocations[0].long]} icon={locationMarker} />
    </MapContainer>
  );
};

export default MapLayout;
