import styled from 'styled-components'

export const Navbars = styled.div`
display: none;
.main-headers{
    justify-content: space-between;
    display:flex;
}
.logout{
    margin-right: 10px;
    svg{
        font-size:16px;
        margin:0px 5px;
        cursor:pointer;
    }
}
`