import React, { useState, useEffect } from "react";
import { Layout, theme } from "antd";
import PropTypes from 'prop-types';

import Sidebar from "components/Sidebar";
import Navbar from "components/Navbar";
import useGet from "hooks/useGet";
// import useAlert from 'context/alerts'
import { GET_PROFILE } from "constants/api";
import { LayoutWrap } from "styles/components/Sidebar";
import BreadcrumbComponent from "components/Breadcrumb";
const { Content } = Layout;

const PrivateLayout = ({ children }) => {
  const { mutateAsync } = useGet();
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const [userDetails, setUserDetails] = useState({});
  const [itemsPath, setItemsPath] = useState([]);
  const [selectedKey, setSelectedKey] = useState(localStorage.getItem('selectedKey') || 1);
  useEffect(() => {
    const account_number = localStorage.getItem("account_number");
    const PROFILE_LINK = `${GET_PROFILE}${account_number}:accounts:&action=get_user`;
    mutateAsync({
      url: PROFILE_LINK,
      token: true,
    }).then((response) => {
      setUserDetails(response?.response?.payload)
    }).catch((error) => {
      console.error(error)
    });
  }, []);

  const childrenToRender = React.Children.toArray(children);

  return (
    <LayoutWrap style={{overflow: 'hidden'}}>
    <Layout className="handle-sidebar">
      <div className="top-header">
      <Sidebar selectedKey={selectedKey} setSelectedKey={setSelectedKey} userDetails={userDetails} collapsed={collapsed} setCollapsed={setCollapsed} />
      </div>
      <Layout className="layout-composition">
        <Navbar
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          colorBgContainer={colorBgContainer}
        />
        <BreadcrumbComponent items={itemsPath} />
        <Content
          className="mob-layout"
          style={{
            overflow: "auto",
            margin: "10px 0 10px 10px",
            padding: 12,
            minHeight: 280,
            // background: colorBgContainer,
            background: "#f5f5f5",
          }}
        >
          {childrenToRender.map((child) =>
            React.cloneElement(child, {
              userDetails,
              setUserDetails,
              setItemsPath,
              setSelectedKey
            })
          )}
        </Content>
      </Layout>
    </Layout>
    </LayoutWrap>
  );
};

PrivateLayout.prototype = {
  collapsed: PropTypes.bool.isRequired
}
export default PrivateLayout;
