import React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";
import {toast} from "react-toastify"
import { LoginOutlined, UserOutlined } from "@ant-design/icons";
// import { LoginRoute } from "constants/routes";
import { Navbars } from "styles/components/Navbar";
import usePost from "hooks/usePost";
import { LOGOUT } from "constants/api";
import useMetaData from 'context/metaData';
const { Header } = Layout;

const Navbar = ({ colorBgContainer }) => {
  const {setCustmerDetails} = useMetaData();
  // const navigate = useNavigate();
  const {mutateAsync: logoutProfile} = usePost()

  const logout = () => {
    const api_token = localStorage.getItem('token');
    logoutProfile({
      url: `${LOGOUT}${api_token}`,
      payload: {},
      token: true
    }).then((res) => {
      toast.success(res?.response?.message)
      localStorage.clear();
      setCustmerDetails({})
      // navigate(LoginRoute.path);
    }).catch((err) => {
      console.error(err)
    })
  }
  return (
    <Navbars>
      <Header
        className="main-headers"
        style={{
          padding: 0,
          background: colorBgContainer,
        }}
      >
        <div></div>
        <div>
          <span className="logout">
            <Link to="/profile">
              {" "}
              <UserOutlined style={{color: '#283587'}} />
            </Link>
            <LoginOutlined
              onClick={() => logout()}
            />
          </span>
        </div>
      </Header>
    </Navbars>
  );
};

export default Navbar;
