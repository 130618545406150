import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { LineChartSection } from "styles/pages/Report";

const ZoomableLineChart = ({
  reportData,
  rangeDateData,
  deviceReport = false,
  tagsCategory = false,
}) => {
  const [userState, setUserState] = useState({});
  const [currentDim, setCurrentDim] = useState({
    width: 400,
    height: 400,
  });


  useEffect(() => {
    if (window.innerWidth < 480 && window.innerWidth > 360) {
      setCurrentDim({
        height: 350,
        width: 350,
      });
    } else if (window.innerWidth <= 360) {
      setCurrentDim({
        height: 300,
        width: 300,
      });
    } else {
      setCurrentDim({
        height: "auto",
        width:"100%",
      });
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (tagsCategory) {
      try {
        const series = [];
        let labelsValue = [];
        Object.keys(reportData).forEach((report) => {
          // updatedReportData[report][key] = reportData[report][key]
          const seriesValue = {
            name: '',
            data: typeof reportData[report] == 'object' && [].concat(Object.values(reportData[report])),
          };
          series.push(seriesValue);
          const labels = Object.keys(reportData[report]);
          labelsValue = labels;
        });
  
        setUserState({
          stroke: {
            curve: "smooth",
            width: 2, // Set the desired width for the graph line here
          },
          markers: {
            size: 2, // Set the size of the data points
            strokeWidth: 0, // Set the border width of the data points (0 for no border)
            colors: ["#283587"], // Set the color of the data points
          },
          chart: {
            id: "zoomable-line",
            toolbar: {
              show: true,
              autoSelected: "zoom", // Show zoom button by default
            },
            zoom: {
              enabled: true, // Enable zooming functionality
            },
          },
          xaxis: {
            categories: labelsValue,
          },
          yaxis: {
            tickPlacement: "on",
            tickAmount: 'dataPoints',
            decimalsInFloat: 0,
            min: 0,
            max: 100,
            labels: {
              rotate: 0,
              rotateAlways: false,
              formatter: function (val) {
                return Math.round(val).toFixed(0);
              }
            },
          },
          tooltip: {
            y: {
              formatter: function (value) {
                return Math.floor(value);
              }
            }
          },
          series: {
            name: '',
            data: series
          },
        });
      } catch (err) {
        console.error(err);
      }
      // const updatedReportData = {}
    } else if (deviceReport) {
      try {
        const updatedReportData = {};
        const series = [];
        reportData.forEach((report, index) => {
          for (let key in report) {
            updatedReportData[key] = { ...rangeDateData };
            for (let reportkey in report[key]) {
              updatedReportData[key][reportkey] =
                reportData[index][key][reportkey];
            }
            const seriesValue = {
              name: '',
              data: typeof updatedReportData[key] == 'object' && [].concat(Object.values(updatedReportData[key])),
            };
            series.push(seriesValue);
          }
        });
        const labelsValue = [].concat(Object.keys({ ...rangeDateData }));
        setUserState({
          stroke: {
            curve: "smooth",
            width: 2, // Set the desired width for the graph line here
          },
          markers: {
            size: 2, // Set the size of the data points
            strokeWidth: 0, // Set the border width of the data points (0 for no border)
            colors: ["#283587"], // Set the color of the data points
          },
          chart: {
            id: "zoomable-line",
            toolbar: {
              show: true,
              autoSelected: "zoom", // Show zoom button by default
            },
            zoom: {
              enabled: true, // Enable zooming functionality
            },
          },
          xaxis: {
            categories: labelsValue,
          },
          series: series,
        });
      } catch (err) {
        console.error(err);
      }
    } else if (
      Object.keys(reportData).length >= 0 &&
      Object.keys(rangeDateData).length > 0
    ) {
      try {
        const updatedReportData = { ...rangeDateData };
        for (let key in reportData) {
          updatedReportData[key] = reportData[key];
        }
        const seriesValue = [].concat(Object.values(updatedReportData));
        const labelsValue = [].concat(Object.keys(updatedReportData));
        setUserState({
          stroke: {
            curve: "smooth",
            width: 2, // Set the desired width for the graph line here
          },
          markers: {
            size: 2, // Set the size of the data points
            strokeWidth: 0, // Set the border width of the data points (0 for no border)
            colors: ["#0c5273"], // Set the color of the data points
          },
          chart: {
            id: "zoomable-line",
            toolbar: {
              show: true,
              autoSelected: "zoom", // Show zoom button by default
            },
            zoom: {
              enabled: true, // Enable zooming functionality
            },
          },
          xaxis: {
            categories: labelsValue,
          },
          series: [
            {
              name: "",
              data: seriesValue,
            },
          ],
        });
      } catch (err) {
        console.error(err);
      }
    }
  }, [reportData, rangeDateData]);

  return (
    <LineChartSection>
      {Object.keys(userState).length > 0 && (
        <Chart
          options={userState}
          series={userState.series}
          type="line"
          height={currentDim.height}
          width={currentDim.width}
        />
      )}
    </LineChartSection>
  );
};

export default ZoomableLineChart;
