import styled from "styled-components";
export const ReportSection = styled.section`
.failed-response{
  text-align: center;
  margin-top: 30px;
  img{
    height: auto;
    object-fit: cover;
  }
}
  .customtabledata {
    border-spacing: 0px;
    border: 1px solid #000;
    border-bottom: 0px solid #000;
    border-right: 0px solid #000;

    td,
    th {
      border-bottom: 1px solid #000;
      border-right: 1px solid #000;
      padding: 5px 10px;
    }
  }
  .react-datepicker-wrapper {
    margin-top: 20px;
    .react-datepicker__calendar-icon{
      top:4px;
    }
    input {
      border-radius: 5px;
      padding: 0px 20px 0px 30px;
    height: 35px;
      font-size: 14px;
      border: 1px solid #d9d9d9;
    }
  }
  .ant-select {
    margin-top: 20px;
    margin-left: 20px;
    .ant-select-selector{
        height: 37px;
    }
  }
  @media (max-width: 620px) {
    .react-datepicker-wrapper {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
  @media (max-width:992px) {
   .ant-col.selectmonth{
    .ant-select {
    margin-left: 0;
   }
   } 
  }
`;
export const PieChartSection = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    overflow: hidden;
    width: 100%;

    background: #fff;
    border-radius: 5px;
    padding: 15px;
    box-shadow: -6px 2px 19px #ddd;
    @media (max-width:991px) {
      box-shadow: none;
    }
   /* .apexcharts-canvas,svg{
    width: 100% !important;
    height: 100% !important;
   }
  svg  foreignObject{
      height:100%;
    } */
  .apexcharts-legend {
    /* max-width: 350px !important;  */
  }
`;
export const LineChartSection = styled.div`
    overflow: hidden;
    height: 100%;
    /* min-height: 250px; */
    @media (max-width: 480px) {
        overflow: auto;
    }
`;
