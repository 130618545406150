import React, { useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { useIsFetching, useIsMutating } from 'react-query'
import { Spin } from 'antd'

import useLoader, { LoaderProvider } from 'context/loader'
import { AlertProvider } from 'context/alerts'
// import Alerts from 'components/Alerts'
import routes from 'constants/routes'
import PrivateLayout from 'components/Layout/PrivateLayout'
import PublicLayout from 'components/Layout/PublicLayout'
import { MetaDataProvider } from 'context/metaData'

export const RenderRoutes = () => {
  // const { custmerDetails } = useMetaData();
  const accountType = localStorage.getItem('account_type')
  const accessToken = localStorage.getItem('token')
  const isFetching = useIsFetching()
  const isMutating = useIsMutating()
  const { loader, setLoader } = useLoader()

  useEffect(() => {
    if (isFetching || isMutating){
      setLoader(true)      
    } 
    else {
      setLoader(false)      
    } 
  }, [isFetching, isMutating])

  if (accessToken) {
    return (
      <Routes>
        {routes[accountType].map((route, index) => {
          const { component: Component, path, } = route
          return (
            <Route
              key={index}
              path={path}
              element={
                <>
                <Spin className={`${loader && 'custom-loader'}`} size="large" spinning={loader}></Spin>
                <PrivateLayout>
                  <Component />
                </PrivateLayout>
                </>
              }
            />
          )
        })}
        <Route path='*' element={<Navigate to="/" />} />
      </Routes>

    )
  } else {
    return (
      <Routes>
        {routes['UN_AUTH_ROUTES'].map((route, index) => {
          const { component: Component, path, } = route
          return (
            <Route
              key={index}
              path={path}
              element={
                <PublicLayout>
                  <Spin className={`${loader && 'custom-loader'}`} size="large" spinning={loader}></Spin>
                  <Component />
                </PublicLayout>
              }
            />

          )
        })}
        <Route path='*' element={<Navigate to="/" />} />
      </Routes>
    )
  }
}

const AppRoutes = () => {

  return (
    <AlertProvider>
      <LoaderProvider>
        <MetaDataProvider>
          <RenderRoutes />
        </MetaDataProvider>
      </LoaderProvider>
    </AlertProvider>
  )
}

export default AppRoutes
