import React, { useState } from "react";
import moment from "moment";
import { Row, Col, Pagination } from "antd";
import { WeiboOutlined, PlusOutlined } from "@ant-design/icons";
import {
  ListSection,
  TimeStamp,
  Info,
  Infosubheading,
  PaginationLayout,
  ListLayout,
  AddBtn,
  AddDevice,
  DateTime
} from "styles/pages/Devices";
import { useNavigate } from "react-router-dom";
import AddNewDevice from "./AddNewDevice";

const MyDevices = ({
  deviceList,
  currentPage,
  onPageChange,
  totalDeviceCount,
}) => {
  const history = useNavigate();
  const DevicesDetails = (id) => {
    history(`/device/${id}`)
  };
  const [open, setOpen] = useState(false);

  return (
    <>
      <AddDevice><AddBtn onClick={() => setOpen(true)}><PlusOutlined style={{ marginRight: "10px" }} />Add Device</AddBtn></AddDevice>
      {deviceList.length > 0 ? (
        <ListLayout>
          {deviceList.map((device, index) => {
            const timestamp = moment(device.update_ts, "YYYY-MM-DD HH:mm:ss");
            return (
              <ListSection onClick={() => DevicesDetails(device?.device_id)} key={index}>
                <Row>
                  <Col >
                    <Row>
                      <Col>
                        <WeiboOutlined
                          style={{
                            fontSize: "30px",
                            marginTop: "15px",
                            marginRight: "10px",
                          }}
                        />
                      </Col>
                      <Col>
                        <Info title={device.name}>{device.name}</Info>
                        {!!device.rsn && <Infosubheading>RSN: {device.rsn}</Infosubheading>}
                        <div>
                          <Infosubheading style={{ fontWeight: '700' }}>{(device.device_type).split('_').join(' ').replace(/\b\w/g, (char) => char.toUpperCase())}</Infosubheading>
                          <Infosubheading title={device?.location_address || ''}>{device.location_address == null || device.location_address == '' ? '' : device.location_address}</Infosubheading>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <TimeStamp active={device?.is_online.toString()}>
                  <div className="circle"></div>
                  <DateTime>
                    {moment(timestamp).format("MMM DD, YYYY,")}{" "}
                    {moment(timestamp).format("hh:mm:ss A")}
                  </DateTime>
                </TimeStamp>
              </ListSection>
            );
          })}
        </ListLayout>
      ) : null}
      {!!deviceList.length && <PaginationLayout>
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          pageSize={20}
          onChange={onPageChange}
          total={totalDeviceCount}
        />
      </PaginationLayout>}
      <AddNewDevice open={open} setOpen={setOpen} onPageChange={onPageChange} />
    </>
  );
};

export default MyDevices;
