import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { Tabs } from "antd";
import useGet from "hooks/useGet";
import { GET_ALL_DEVICELIST } from "constants/api";
import MyDevices from "./MyDevices";
import SelectedDevices from "./SelectedDevices";
import { DeviceTabs } from "styles/pages/Devices";

const Devices = ({ setItemsPath, setSelectedKey }) => {
  const { mutateAsync: getDeviceList } = useGet();
  const [deviceList, setDeviceList] = useState([]);
  const [totalDeviceCount, setTotalDeviceCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const path = [
      {
        title: <Link to="/dashboard">Dashboard</Link>
      },
      {
        title: <Link to="/device">Devices</Link>
      }
    ]
    setItemsPath(path)
    setSelectedKey(2)
  }, []);

  useEffect(() => {
    onPageChange(1, 20)
  }, []);

  const onPageChange = (current) => {
    setCurrentPage(current)
    const account_number = localStorage.getItem("account_number");
    const currPage = `&page=${current}&resource=jio:jttms:`
    const URL = `${GET_ALL_DEVICELIST}${currPage}${account_number}:device:`;
    getDeviceList({
      url: URL,
      token: true,
    }).then((devices) => {
      setDeviceList(devices.response.payload);
      setTotalDeviceCount(devices?.meta?.count)
      toast.success(devices.response.message)
    }).catch((error) => {
      console.error(error)
    });
  };

  const items = [
    {
      key: '1',
      label: `My Devices`,
      children: <MyDevices deviceList={deviceList} currentPage={currentPage} onPageChange={onPageChange} totalDeviceCount={totalDeviceCount} />,
    },
    {
      key: '2',
      label: `Select Devices`,
      children: <SelectedDevices deviceList={deviceList} currentPage={currentPage} onPageChange={onPageChange} totalDeviceCount={totalDeviceCount} />,
    },
  ];

  const onChange = (key) => {
    console.log(key);
  };

  return (
    <DeviceTabs>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </DeviceTabs>
  );
};

export default Devices;
