// import Login from 'pages/LandingPage/Login'
import SignUp from 'pages/LandingPage/SignUp'
import Dashboard from 'pages/Dashboard'
import LoginFromPhone from 'pages/LandingPage/LoginSource'
import ProfilePage from 'pages/Profile'
import Devices from 'pages/Devices'
import DevicesDetails from 'pages/DeviceDetails/devicesDetails'
import Reports from 'pages/Reports'
import TrackedLocation from 'pages/TrackedLocation'

export const LoginRoute = {
  component: LoginFromPhone,
  path: '/',
  restricted: false,
  jttms_operations: false,
  access: ['jttms_customer']
}

export const LoginPhoneRoute = {
  component: LoginFromPhone,
  path: '/login-phone',
  restricted: false,
  jttms_operations: false,
  access: ['jttms_customer']
}

export const SignUpRoute = {
  component: SignUp,
  path: '/register',
  restricted: false,
  jttms_operations: false,
  access: ['jttms_customer']
}

export const DashboardRoute = {
  component: Dashboard,
  path: '/',
  restricted: true,
  jttms_operations: false,
  access: ['jttms_customer','jttms_operations']
}

export const ProfilePageRoute = {
  component: ProfilePage,
  path: '/profile',
  restricted: true,
  jttms_operations: false,
  access: ['jttms_customer','jttms_operations']
}
export const DevicePageRoute = {
  component: Devices,
  path: '/devices',
  restricted: true,
  jttms_operations: false,
  access: ['jttms_customer','jttms_operations']
}
export const DeviceDetailsRoute = {
  component: DevicesDetails,
  path: '/device/:id',
  restricted: true,
  jttms_operations: false,
  access: ['jttms_customer','jttms_operations']
}
export const TrackedLocationRoute = {
  component: TrackedLocation,
  path: '/last-location/:id',
  restricted: true,
  jttms_operations: false,
  access: ['jttms_customer','jttms_operations']
}
export const ReportsRoute = {
  component: Reports,
  path: '/reports',
  restricted: true,
  jttms_operations: true,
  access: ['jttms_operations']
}


const IRouteS = {
  "UN_AUTH_ROUTES": [
    LoginRoute,
    SignUpRoute,
    LoginPhoneRoute,
  ],
  "jttms_customer": [
    DashboardRoute,
    ProfilePageRoute,
    // DevicePageRoute,
    // DeviceDetailsRoute,
    // TrackedLocationRoute
  ],
  "jttms_operations": [
    DashboardRoute,
    ProfilePageRoute,
    // DevicePageRoute,
    // DeviceDetailsRoute,
    // ReportsRoute,
    // TrackedLocationRoute
  ]
}

export default IRouteS
