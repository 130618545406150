import React, { useState } from 'react';
import { Steps, } from 'antd';
import { useNavigate } from 'react-router-dom';
import SendOtp from './SendOtp';
import ValidateOtp from './ValidateOtp';
import CreateAccount from './CreateAccount';
import { GlobalStyle, Section, Wrapper, Title, WrapDiv } from "styles/pages/LandingPage";
import { LoginRoute } from 'constants/routes';

const SignUp = () => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const steps = [
    {
      title: 'Register your device',
      content: <SendOtp next={next} />,
    },
    {
      title: 'Verify OTP',
      content: <ValidateOtp next={next} />,
    },
    {
      title: 'Create Account',
      content: <CreateAccount />,
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <>
    <GlobalStyle />
      <Section>
        <Wrapper>
          <Title>Jio Things</Title>
          <Steps style={{marginTop:"20px"}} current={current} items={items} />
      <div style={{width:"100%",padding:"10px 20px"}}>{steps[current].content}</div>
      {/* <div
        style={{
          marginTop: 24,
        }}
      > */}
        {/* {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button type="primary" onClick={() => message.success('Processing complete!')}>
            Done
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: '0 8px',
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )} */}
      {/* </div> */}
        <WrapDiv>
                <p onClick={() => navigate(LoginRoute.path)}>{`Already have an account`}</p>
            </WrapDiv>
        </Wrapper>
      </Section>
      
    </>
  );
};
export default SignUp;