import styled from "styled-components";
export const ProfileSection = styled.section`
  text-align: center;
  .gutter-row {
    padding-bottom: 15px;
  }
  .ant-card-body {
    box-shadow: -9px 9px 20px #ddd;
  }
`;
export const ProfileImage = styled.div`
  background: #008ecc;
  padding: 11px 0;
  border-radius: 12px;
  position: relative;
  box-shadow: -9px 9px 20px #ddd;
  h2 {
    color: #fff;
    font-size: 20px;
  }
  .edit-icon {
    position: absolute;
    top: 3px;
    right: 10px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #283587;
  margin-bottom: 30px;
`;
export const SubHeadingText = styled.div`
  color: #283587;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  margin: 0px;
  .ant-select-selector {
    border: 1px solid #5a50c1;
    border-radius: 10px;
    height: 26px;
  }
`;
export const InputText = styled.input`
  color: #283587;
  font-size: 15px;
  font-weight: 600;
  margin: 0px;
  border-radius: 10px;
  border: 1px solid #5a50c1;
  text-align: left;
  padding-left: 10px;
  width: 214px;
`;
export const SubHeading = styled.p`
  color: #000;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  text-align: left;
  margin: 0px;
`;
export const ImageBox = styled.div`
  color: #000;
  font-size: 18px;
  margin-right: 10px;
`;
export const ItemContent = styled.div`
  display: flex;
  place-items: center;
`;
export const EditIcon = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 10px;
`;
export const UpdateButton = styled.button`
  background-color: #283587;
  color: #fff;
  border: none;
  border-radius: 8px;
  width: 80px;
  height: 30px;
  text-align: center;
  margin: 25px;
`;
export const ErrorText = styled.div`
  color: red;
  font-size: 12px;
`