import React from "react";
import { Tabs } from "antd";
// import { useNavigate } from "react-router-dom/dist";
// import { DashboardRoute } from "constants/routes";
import { GlobalStyle, Section, Wrapper, Title, TabWrap } from "styles/pages/LandingPage";
import LoginWithPhoneOtp from "./LoginWithPhoneOtp";
import LoginWithEmailOtp from "./LoginWithEmailOtp";

const LoginFromPhone = () => {

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: `Login Via Phone`,
      children: <LoginWithPhoneOtp />,
    },
    {
      key: "2",
      label: `Login Via Email`,
      children: <LoginWithEmailOtp />,
    },
  ];

  return (
    <>
      <GlobalStyle />
      <Section>
        <Wrapper>
          <Title>Jio Things</Title>
          <TabWrap>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </TabWrap>
        </Wrapper>
      </Section>
    </>
  );
};

export default LoginFromPhone;
