import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Select } from "antd";
import { ReportSection } from "styles/pages/Report";
import ZoomableLineChart from "./LineChart";
import usePost from "hooks/usePost";
import {
  GET_USER_CREATION_REPORT,
  // GET_USER_LOGGED_IN_REPORT,
  GET_DEVICES_CREATED_REPORT,
  GET_DEVICE_TYPE,
  TAG_CATEGORY_COUNT,
  GET_DEVICE_MODEL,
  // JAP_DEVICES_COUNT
} from "constants/api";
import useGet from "hooks/useGet";
import SomethingWentWrong from 'assets/gif/something-went-wrong.gif';
import DummyData from "./dummyData.json";

const Reports = ({ setItemsPath, setSelectedKey, isDashboardPage=false, showGraph='', graphDeviceType='', selectedRange=30 }) => {
  const { mutateAsync: userReport } = usePost();
  const {mutateAsync: getDeviceTypes} = useGet();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [reportData, setReportData] = useState({});
  const [userLoggedInData, setUserLoggedInData] = useState({});
  const [devicesReport, setDevicesReport] = useState([]);  
  const [userCreationCount, setUserCreationCount] = useState(0);
  const [deviceCreationCount, setDeviceCreationCount] = useState(0);
  // const [userLoggedInCount, setUserLoggedInCount] = useState(0);
  const account_number = localStorage.getItem("account_number");
  const [rangeDateData, setRangeDateData] = useState({});
  const [activeDeviceType, setActiveDeviceType] = useState([]);
  const { Option } = Select;  
  const [deviceModals, setDeviceModals] = useState([]);
  const [tagCategory, setTagCategory] = useState({});
  const [tagsCategoryCount, setTagsCategoryCount] = useState(0);
  const [japDevices, setJapDevices] = useState({});
  const [japDevicesTotalCount, setjapDevicesTotalCount] = useState(0);
  const [jioPhones, setJioPhones] = useState({});
  const [jioPhonesTotalCount, setJioPhonesTotalCount] = useState(0);
  const [errorScreen, setErrorScreen] = useState(false);
  const [watchReport, setWatchReport] = useState({});
  const [watchTotal, setWatchTotal] = useState(0);

  useEffect(() => {
    if(!isDashboardPage) {
      const path = [
        {
          title: <Link to="/dashboard">Dasboard</Link>,
        },
        {
          title: <Link to="/reports">Reports</Link>,
        },
      ];
      setItemsPath(path);
      setSelectedKey(3);
    }
  }, []);

  //Tag Category Count
  useEffect(() => {
    if(!isDashboardPage) {
    getDeviceTypes({
      url: `${GET_DEVICE_MODEL}${account_number}:device:&action=get_device_model_info&info=device_model&device_type=jiotag_ble`,
      token: true
    }).then((res) => {
      setDeviceModals(res?.response?.payload)
    }).catch((error) => {
      console.error(error)
    });
  }
  }, []);

  const createDateStruc = (showGraph) => {
    const newDates = {};
    for (let i = 1; i < DummyData[showGraph].length; i++) {
      const updatedDate = moment().subtract(i, 'days').format("DD-MM-YYYY");
      newDates[updatedDate] = DummyData[showGraph][DummyData[showGraph].length - i];
    }
    return newDates
  }

  const fetchReportData = () => {
    if (!!startDate && !!endDate) { 
      const payload = {
        action: "generate_reports",
        resource: `jio:jttms:${account_number}:reports:`,
        account_type: "jttms_customer",
        from_date: moment(startDate).startOf().format("YYYY-MM-DD HH:mm:ss"),
        to_date: moment(endDate).endOf().format("YYYY-MM-DD HH:mm:ss"),
        daywise: true,
      };

      //fetching watch report
      if(isDashboardPage && (showGraph == 'boat' || showGraph == 'titan' || showGraph == 'noise' || showGraph == 'fire-boltt')) {
        let watch_report = {};
        const startingDate = moment(startDate).format("DD-MM-YYYY");
        const endingDate = moment(endDate).format("DD-MM-YYYY");

        const newDates = createDateStruc(showGraph);
        Object.keys(newDates).forEach((key) => {
          let newDate = moment(key, "DD-MM-YYYY");
          if(moment(startingDate, "DD-MM-YYYY").isSameOrBefore(newDate, 'day') && moment(endingDate, "DD-MM-YYYY").isSameOrAfter(newDate, 'day')) {
            watch_report[key] = newDates[key];
          }
        })

        let total_watch = Object.values(watch_report).reduce((val, oldVal) => val+oldVal, 0);
        setWatchTotal(total_watch);
        setWatchReport(watch_report);
        setErrorScreen(false);
      }

      //Fetching user creation data
      if(!isDashboardPage || (isDashboardPage && showGraph == 'userCreationReport')) {
      userReport({
        url: GET_USER_CREATION_REPORT,
        token: true,
        payload,
      })
        .then((res) => {
          toast.success(res?.message);
          setErrorScreen(false);
          const arrayOfKeyValuePairs = Object.entries(res?.response?.payload);
          arrayOfKeyValuePairs.sort(
            ([keyA], [keyB]) => new Date(keyA) - new Date(keyB)
          );
          const sortedObject = Object.fromEntries(arrayOfKeyValuePairs);
          const formattedDatesData = {};
          Object.keys(sortedObject).forEach((dateKey) => {
            const formattedDateKey = moment(dateKey).format("DD-MM-YYYY");
            formattedDatesData[formattedDateKey] = sortedObject[dateKey];
          });
          setReportData(formattedDatesData);
          setUserCreationCount(res?.meta?.total_count_for_given_range || 0);
        })
        .catch((err) => {
          console.error(err);
          setReportData({});
          setErrorScreen(true);
        });
      }

        //Fetching user logged In data
        if(!isDashboardPage || (isDashboardPage && (showGraph == 'userDailyLoggedInReport' || showGraph == 'userWeeklyLoggedInReport' || showGraph == 'userMonthlyLoggedInReport'))) {
          // if(showGraph == 'userDailyLoggedInReport') {
          //   payload['range'] = "daily";
          // }
          // if(showGraph == 'userWeeklyLoggedInReport') {
          //   payload['range'] = "weekly";
          // }
          // if(showGraph == 'userMonthlyLoggedInReport') {
          //   payload['range'] = "monthly";
          // }
          // userReport({
          //   url: GET_USER_LOGGED_IN_REPORT,
          //   token: true,
          //   payload,
          // })
          //   .then((res) => {
          //     toast.success(res?.message);
          //     setErrorScreen(false);
          //     const arrayOfKeyValuePairs = Object.entries(res?.response?.payload);
          //     arrayOfKeyValuePairs.sort(
          //       ([keyA], [keyB]) => new Date(keyA) - new Date(keyB)
          //     );
          //     const sortedObject = Object.fromEntries(arrayOfKeyValuePairs);
          //     const formattedDatesData = {};
          //     Object.keys(sortedObject).forEach((dateKey) => {
          //       const formattedDateKey = moment(dateKey).format("DD-MM-YYYY");
          //       formattedDatesData[formattedDateKey] = sortedObject[dateKey];
          //     });
          //     setUserLoggedInData(formattedDatesData);
          //     setUserLoggedInCount(res?.meta?.total_count_for_given_range || 0);
          //   })
          //   .catch((err) => {
          //     console.error(err);
          //     setErrorScreen(true);
          //     setUserLoggedInData({});
          //   });

          let activity_report = {};
          const startingDate = moment(startDate).format("DD-MM-YYYY");
          const endingDate = moment(endDate).format("DD-MM-YYYY");

          const newDates = createDateStruc(showGraph);
          Object.keys(newDates).forEach((key) => {
            let newDate = moment(key, "DD-MM-YYYY");
            if(moment(startingDate, "DD-MM-YYYY").isSameOrBefore(newDate, 'day') && moment(endingDate, "DD-MM-YYYY").isSameOrAfter(newDate, 'day')) {
              activity_report[key] = newDates[key];
            }
          })

          // let total_watch = Object.values(activity_report).reduce((val, oldVal) => val+oldVal, 0);
          setUserLoggedInData(activity_report);
          // setUserLoggedInCount(total_watch);
          setErrorScreen(false);
          }

        //Fetching JAP Devices        
        if(!isDashboardPage || (isDashboardPage && showGraph == 'japReport')) {
          //Fetching JAP Devices through API
        // const japDayPayload = {
        //   "action": "generate_reports",
        //   "resource": `jio:jttms:${account_number}:reports:`,
        //   "from_date": moment(startDate).startOf().format("YYYY-MM-DD"),
        //   "to_date": moment(endDate).endOf().format("YYYY-MM-DD"),
        //   "daywise": true
        // }

        // userReport({
        //   url: JAP_DEVICES_COUNT,
        //   payload: japDayPayload,
        //   token: true
        // }).then((res) => {
        //   setErrorScreen(false);
        //   // setJapDevicesDayCount(res);
        //   if(res.meta.total_count_for_given_range != null) {
        //     const arrayOfKeyValuePairs = Object.entries(res?.response?.payload);
        //     arrayOfKeyValuePairs.sort(
        //     ([keyA], [keyB]) => new Date(keyA) - new Date(keyB)
        //   );
        //   const sortedObject = Object.fromEntries(arrayOfKeyValuePairs);
        //   const formattedDatesData = {};
        //   Object.keys(sortedObject).forEach((dateKey) => {
        //     const formattedDateKey = moment(dateKey).format("DD-MM-YYYY");
        //     formattedDatesData[formattedDateKey] = sortedObject[dateKey];
        //   });
        //   setJapDevices(formattedDatesData);
        // }
        // setjapDevicesTotalCount(res?.meta?.total_count_for_given_range || 0);
        // }).catch((err) => {
        //   console.error(err);
        //   setErrorScreen(true);
        // });

        //Dummy data
        let jap_report = {};
        const startingDate = moment(startDate).format("DD-MM-YYYY");
        const endingDate = moment(endDate).format("DD-MM-YYYY");

        const newDates = createDateStruc("jap");
        Object.keys(newDates).forEach((key) => {
          let newDate = moment(key, "DD-MM-YYYY");
          if(moment(startingDate, "DD-MM-YYYY").isSameOrBefore(newDate, 'day') && moment(endingDate, "DD-MM-YYYY").isSameOrAfter(newDate, 'day')) {
            jap_report[key] = newDates[key];
          }
        })

        let total_watch = Object.values(jap_report).reduce((val, oldVal) => val+oldVal, 0);
        setJapDevices(jap_report);
        setjapDevicesTotalCount(total_watch);
        setErrorScreen(false);
      }

        //Fetching JioPhone Devices        
        if(!isDashboardPage || (isDashboardPage && showGraph == 'jiophone')) {

        //Dummy data
        let jio_phones = {};
        const startingDate = moment(startDate).format("DD-MM-YYYY");
        const endingDate = moment(endDate).format("DD-MM-YYYY");

        const newDates = createDateStruc("jiophone");
        Object.keys(newDates).forEach((key) => {
          let newDate = moment(key, "DD-MM-YYYY");
          if(moment(startingDate, "DD-MM-YYYY").isSameOrBefore(newDate, 'day') && moment(endingDate, "DD-MM-YYYY").isSameOrAfter(newDate, 'day')) {
            jio_phones[key] = newDates[key];
          }
        })

        let total_watch = Object.values(jio_phones).reduce((val, oldVal) => val+oldVal, 0);
        setJioPhones(jio_phones);
        setJioPhonesTotalCount(total_watch);
        setErrorScreen(false);
      }
        
      if(!isDashboardPage || (isDashboardPage && showGraph == 'deviceCreationReport')){
        //Fetching device report
        if(activeDeviceType.length > 0 || (isDashboardPage && showGraph == 'deviceCreationReport')) {
          if(graphDeviceType != "jiobook"){
            userReport({
              url: GET_DEVICES_CREATED_REPORT,
              token: true,
              payload: {...payload, device_type: !isDashboardPage ? activeDeviceType : [graphDeviceType]},
            })
            .then((res) => {
              toast.success(res?.message);
              setErrorScreen(false);
              const previousDateData = []
              Object.keys(res?.response?.payload).forEach(key => {
                if(typeof res?.response?.payload[key] === 'object') {
                const arrayOfKeyValuePairs = Object.entries(res?.response?.payload[key]);
                arrayOfKeyValuePairs.sort(
                  ([keyA], [keyB]) => new Date(keyA) - new Date(keyB)
                  );
                  const sortedObject = Object.fromEntries(arrayOfKeyValuePairs);
                  const formattedDatesData = {
                    [key]: {}
                  };
                  Object.keys(sortedObject).forEach((dateKey) => {
                    const formattedDateKey = moment(dateKey).format("DD-MM-YYYY");
                    formattedDatesData[key][formattedDateKey] = sortedObject[dateKey];
                  });
                  previousDateData.push(formattedDatesData)
                }
                })
                setDevicesReport([...previousDateData]);
                setDeviceCreationCount(res?.meta?.total_count_for_given_range || 0);
            })
            .catch((err) => {
              console.error(err);
              setErrorScreen(true);
              setDevicesReport([]);
            });
          } else {
            let device_report = {
              "jiobook": {}
            };
            const startingDate = moment(startDate).format("DD-MM-YYYY");
            const endingDate = moment(endDate).format("DD-MM-YYYY");

            const newDates = createDateStruc("jiobook");
            Object.keys(newDates).forEach((key) => {
              let newDate = moment(key, "DD-MM-YYYY");
              if(moment(startingDate, "DD-MM-YYYY").isSameOrBefore(newDate, 'day') && moment(endingDate, "DD-MM-YYYY").isSameOrAfter(newDate, 'day')) {
                device_report["jiobook"][key] = newDates[key];
              }
            })

            let total_watch = Object.values(device_report["jiobook"]).reduce((val, oldVal) => val+oldVal, 0);
            setDevicesReport([device_report]);
            setDeviceCreationCount(total_watch);
            setErrorScreen(false);
          }
      }
    }

      //Fetching Tags Category
      if(deviceModals.length > 0 && !isDashboardPage) {
        const tagsCategorypayload = {
          "action": "generate_reports",
          "resource": `jio:jttms:${account_number}:reports:`,
          "from_date": moment(startDate).startOf().format("YYYY-MM-DD HH:mm:ss"),
          "to_date": moment(endDate).endOf().format("YYYY-MM-DD HH:mm:ss"),
          "device_model": deviceModals.map((deviceModel) => deviceModel.device_model),
          "category": ["mobile", "laptop", "suitcase"]
        }
        userReport({
          url: TAG_CATEGORY_COUNT,
          payload: tagsCategorypayload,
          token: true
        }).then((res) => {
          toast.success(res?.message);
          setErrorScreen(false);
          const tags_categories = res.response.payload;
          if(Object.keys(tags_categories).length > 0) {
            for (let tags in tags_categories) {
                delete tags_categories[tags][`total_${tags}`];
            }
          }
          setTagCategory(tags_categories);
          setTagsCategoryCount(res?.meta?.total_count_for_given_range || 0);
        }).catch((err) => {
          console.error(err)
          setErrorScreen(true);
          setTagCategory({})
        })
      }
    }
  };

  const handleDateRangeChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      const currentDate = moment(start);
      const lastDate = moment(end);
      let obj = {};
      while (currentDate.valueOf() <= lastDate.valueOf()) {
        let key = currentDate.format("DD-MM-YYYY");
        obj[key] = 0;
        currentDate.add(1, "days");
      }
      setRangeDateData({...obj});
    }
  };

  useEffect(() => {
    if(!isDashboardPage) {
    const URL = `${GET_DEVICE_TYPE}${account_number}:device:&action=get_device_static_info&info=device_type`
    getDeviceTypes({
      url: URL,
      token: true,
    }).then((res) => {
      if (res?.response?.payload.length > 0) {
        const deviceTypes = res.response?.payload;
        const types = deviceTypes.filter((device) => device?.is_active).map((device) => device.name)
        // setSelectedType(types[0])
        setActiveDeviceType(types)
      }
    }).catch((error) => {
      console.error(error)
    });
  }
  }, []);

  useEffect(() => {
    if(!isDashboardPage) {
      handleDateRangeChange([moment().subtract(30, "days")._d, moment()._d]);
    } else {
      setStartDate(moment().subtract(selectedRange, "days").startOf('day')._d);
      setEndDate(moment().subtract(1, "days").endOf('day')._d);
      handleDateRangeChange([moment().subtract(selectedRange, "days").startOf('day')._d, moment().subtract(1, "days").endOf('day')._d]);
    }
  }, [selectedRange]);

  useEffect(() => {
      setReportData({})
      setErrorScreen(false)
      fetchReportData();
  }, [rangeDateData, activeDeviceType, deviceModals, graphDeviceType, showGraph ]);

  const handleDateSelection = (value) => {
    setReportData({});
    setUserLoggedInData({});
    setDevicesReport([]);
    setTagCategory({});
    handleDateRangeChange([moment().subtract(value, "days")._d, moment().subtract(1, "days")._d]);
  };

  const customOptions = (options = '') => {
    if (options == 'userDailyLoggedInReport') {
      return (
        <>
          <Option value={1}>Last Day</Option>
          <Option value={2}>Last 2 Days</Option>
          <Option value={3}>Last 3 Days</Option>
        </>
      )
    } else if (options == 'userWeeklyLoggedInReport') {
      return (
        <>
          <Option value={7}>Last Week</Option>
          <Option value={14}>Last 2 Weeks</Option>
          <Option value={21}>Last 3 Weeks</Option>
        </>
      )
    } else if (options == 'userMonthlyLoggedInReport') {
      return (
        <>
          <Option value={30}>Last Month</Option>
          <Option value={60}>Last 2 Months</Option>
          <Option value={90}>Last 3 Months</Option>
        </>
      )
    } else {
      return (
        <>
          <Option value={1}>Last Day</Option>
          <Option value={7}>Last Week</Option>
          <Option value={30}>Last Month</Option>
          <Option value={90}>Last 3 Months</Option>
        </>
      )
    }
  }
  return (
    <ReportSection>
      <div>
        {!isDashboardPage && 'Reports'}
        <Row style={{ display: "flex",gap:"0px 20px" }}>
          <Col>
            <DatePicker
              showIcon
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={handleDateRangeChange}
              isClearable={true}
              maxDate={new Date()}
              minDate={moment().subtract(90, "days")._d}
            />
          </Col>
          <Col  className="selectmonth">
            <Select
              defaultValue={selectedRange}
              style={{ width: 200 }}
              onChange={handleDateSelection}
            >
              {customOptions(showGraph)}
            </Select>
          </Col>
        </Row>
        <div>
          <Row gutter={10} style={{ gap: "10px 0px", alignItems: "center" }}>
            {(!isDashboardPage || (isDashboardPage && showGraph == 'userCreationReport')) &&
            <Col span={isDashboardPage && showGraph == 'userCreationReport' ? 24 : 12} xl={isDashboardPage && showGraph == 'userCreationReport' ? 24 : 12} md={isDashboardPage && showGraph == 'userCreationReport' ? 24 : 12} xs={24}>
              {!isDashboardPage && <h1>User Creation Report</h1>}
              {!!Object.keys(rangeDateData).length && !errorScreen && (
                  <ZoomableLineChart
                    rangeDateData={rangeDateData}
                    reportData={reportData}
                  />
                )}
              {!errorScreen && <b>Total- {userCreationCount}</b>}
              {errorScreen &&
              <div className="failed-response">
                <img src={SomethingWentWrong} width="400px" height="300px" />
              </div>
              }
            </Col>
            }
            {(isDashboardPage && (showGraph == 'boat' || showGraph == 'titan' || showGraph == 'noise' || showGraph == 'fire-boltt')) &&
            <Col span={24} xl={24} md={24} xs={24}>
              {!isDashboardPage && <h1>User Creation Report</h1>}
              {!!Object.keys(rangeDateData).length && !!Object.keys(watchReport).length && !errorScreen && (
                  <ZoomableLineChart
                    rangeDateData={rangeDateData}
                    reportData={watchReport}
                  />
                )}
              {!errorScreen && <b>Total- {watchTotal}</b>}
              {errorScreen &&
              <div className="failed-response">
                <img src={SomethingWentWrong} width="400px" height="300px" />
              </div>
              }
            </Col>
            }
            {(!isDashboardPage || (isDashboardPage && (showGraph == 'userDailyLoggedInReport' || showGraph == 'userWeeklyLoggedInReport' || showGraph == 'userMonthlyLoggedInReport'))) &&
            <Col span={isDashboardPage && (showGraph == 'userDailyLoggedInReport' || showGraph == 'userWeeklyLoggedInReport' || showGraph == 'userMonthlyLoggedInReport') ? 24 : 12} xl={isDashboardPage && (showGraph == 'userDailyLoggedInReport' || showGraph == 'userWeeklyLoggedInReport' || showGraph == 'userMonthlyLoggedInReport') ? 24 : 12} md={isDashboardPage && (showGraph == 'userDailyLoggedInReport' || showGraph == 'userWeeklyLoggedInReport' || showGraph == 'userMonthlyLoggedInReport') ? 24 : 12} xs={24}>
              {!isDashboardPage && <h1>User Logged In Report</h1>}
              {!!Object.keys(rangeDateData).length && !errorScreen && (
                  <ZoomableLineChart
                    rangeDateData={rangeDateData}
                    reportData={userLoggedInData}
                  />
                )}
              {/* {!errorScreen && <b>Total- {userLoggedInCount}</b>} */}
              {errorScreen &&
              <div className="failed-response">
                <img src={SomethingWentWrong} width="400px" height="300px" />
              </div>
              }
              {/* <TotalData reportData={reportData} /> */}
            </Col>
            }
            {(!isDashboardPage || (isDashboardPage && showGraph == 'deviceCreationReport')) &&
            <Col span={isDashboardPage && showGraph == 'deviceCreationReport' ? 24 : 12} xl={isDashboardPage && showGraph == 'deviceCreationReport' ? 24 : 12} md={isDashboardPage && showGraph == 'deviceCreationReport' ? 24 : 12} xs={24}>
              {!isDashboardPage && <h1>Device Creation Report</h1>}
              {!!Object.keys(rangeDateData).length && !errorScreen && (
                  <ZoomableLineChart
                    rangeDateData={rangeDateData}
                    reportData={devicesReport}
                    deviceReport={true}
                  />
                )}
              {!errorScreen && <b>Total- {deviceCreationCount}</b>}
              {errorScreen &&
              <div className="failed-response">
                <img src={SomethingWentWrong} width="400px" height="300px" />
              </div>
              }
            </Col>
            }
            {(!isDashboardPage || (isDashboardPage && showGraph == 'japReport')) &&
            <Col span={isDashboardPage && showGraph == 'japReport' ? 24 : 12} xl={isDashboardPage && showGraph == 'japReport' ? 24 : 12} md={isDashboardPage && showGraph == 'japReport' ? 24 : 12} xs={24}>
              {!isDashboardPage && <h1>JAP Devices Report</h1>}
              {!!Object.keys(rangeDateData).length && !errorScreen && (
                  <ZoomableLineChart
                    rangeDateData={rangeDateData}
                    reportData={japDevices}
                  />
                )}
              {!errorScreen && <b>Total- {japDevicesTotalCount}</b>}
              {errorScreen &&
              <div className="failed-response">
                <img src={SomethingWentWrong} width="400px" height="300px" />
              </div>
              }
            </Col>
            }
            {(!isDashboardPage || (isDashboardPage && showGraph == 'jiophone')) &&
            <Col span={isDashboardPage && showGraph == 'jiophone' ? 24 : 12} xl={isDashboardPage && showGraph == 'jiophone' ? 24 : 12} md={isDashboardPage && showGraph == 'jiophone' ? 24 : 12} xs={24}>
              {!isDashboardPage && <h1>Jio Phone Report</h1>}
              {!!Object.keys(rangeDateData).length && !errorScreen && (
                  <ZoomableLineChart
                    rangeDateData={rangeDateData}
                    reportData={jioPhones}
                  />
                )}
              {!errorScreen && <b>Total- {jioPhonesTotalCount}</b>}
              {errorScreen &&
              <div className="failed-response">
                <img src={SomethingWentWrong} width="400px" height="300px" />
              </div>
              }
            </Col>
            }
            {!isDashboardPage &&
            <Col span={12} xl={12} md={12} xs={24}>
              {!isDashboardPage && <h1>Tags Category Report</h1>}
              {!!Object.keys(tagCategory).length && !errorScreen && (
                  <ZoomableLineChart
                    rangeDateData={rangeDateData}
                    reportData={tagCategory}
                    tagsCategory={true}
                  />
                )}
              {!errorScreen && <b>Total- {tagsCategoryCount}</b>}
              {errorScreen &&
              <div className="failed-response">
                <img src={SomethingWentWrong} width="400px" height="300px" />
              </div>
              }
            </Col>
            }
          </Row>
        </div>
      </div>
    </ReportSection>
  );
};

export default Reports;
