import React from 'react'
import { Breadcrumb } from 'antd';

const BreadcrumbComponent = ({items}) => {
  return (
    <Breadcrumb
    style={{padding: '10px 0 0 16px', fontWeight: '700'}}
    items={items}
  />
  )
}

export default BreadcrumbComponent