export const GET_OTP = 'accounts/get-otp/'
export const LOGIN = 'accounts/login/'
export const GET_PROFILE = 'accounts/get-user/?resource=jio:aaa:'
export const GET_PROFILE_PIC_UPLOAD_URL = 'accounts/upload-profile-pic/'
export const GET_PROFILE_PIC_DOWNLOAD_URL = 'accounts/download-profile-pic/?resource=jio:aaa:'
export const UPDATE_PROFILE = 'accounts/update-account/'
export const GET_ALL_DEVICELIST = 'device/get-device/?action=get_device'
export const GET_DEVICE_DETAIL = 'device/get-device/?action=get_device&page=1&resource=jio:jttms:'
export const UPDATE_DEVICE_DETAIL = 'device/update-device/'
export const DELETE_ONBOARDED_DEVICE = 'device/delete-device/'
export const CREATE_NEW_DEVICE = 'device/create-device/'
export const GET_DEVICE_TYPE = 'device/get-device-static-info/?resource=jio:jttms:'
export const LOGOUT = 'accounts/logout/?JCMS-API-TOKEN='
export const OTP_ALTERNATIVE_LOGIN = 'accounts/send-otp-alternate-login/'
export const GET_DEVICE_LOCATION = 'locationtracking/get-location/'
export const GET_USER_CREATION_REPORT = 'reports/users-created/count/'
export const GET_USER_LOGGED_IN_REPORT = 'reports/users-logged-in/count/'
export const GET_DEVICES_CREATED_REPORT = 'reports/devices-created/count/'
export const REGISTER_SEND_OTP = 'accounts/signup-otp/'
export const VALIDATE_SIGNUP_OTP = 'accounts/validate-otp/'
export const REGISTER_NEW_ACCOUNT = 'accounts/register-account/'
export const SEND_OTP_DEVICE = 'accounts/send-otp/'
export const SEND_OTP_TRACK_DEVICE = 'accounts/send-otp/'
export const START_TRACKING = '/device/tracking/'
export const GET_DEVICE_MODEL = '/device/get-device-model-info/?resource=jio:jttms:'
export const DEVICE_CREATED_COUNT = 'reports/devices-created/count/'
export const USER_TAG_ALLOCATION = 'reports/user-device-allocation/count/'
export const DEVICE_COUNT = 'reports/devices/count/'
export const TAG_CATEGORY_COUNT = 'reports/tags-category/count/'
export const JAP_DEVICES_COUNT = 'reports/jap-devices/count/'