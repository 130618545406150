import React from 'react'
import { Button, Form, Input, Radio, DatePicker } from 'antd';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import usePost from 'hooks/usePost';
import useMetaData from 'context/metaData';
import { REGISTER_NEW_ACCOUNT } from 'constants/api';
import { LoginRoute } from 'constants/routes';

const CreateAccount = () => {
    const navigate = useNavigate();
    const { mutateAsync: createAccount } = usePost();
    const { registerPhoneNumber, registerUserToken } = useMetaData();
    const onFinish = (values) => {
        const payload = {
            "action": "create_account",
            "account_name": values.account_name,
            "account_type": "jttms_customer",
            "email": values.email,
            "phone_number": registerPhoneNumber,
            "alternate_phone_number": values.alternate_phone_number,
            "user_details": {
                "gender": values.gender,
                "dob": moment(values.dob).format('YYYY-MM-DD')
            }
        };
        createAccount({ url: REGISTER_NEW_ACCOUNT, payload, customHeaders: {'REGISTER-USER-TOKEN': registerUserToken} }).then((res) => {
            navigate(LoginRoute.path)
            toast.success(res?.response?.message);
        }).catch((error) => {
            console.error(error)
        });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <Form
            name="basic"
            labelCol={{
                span: 10,
            }}
            wrapperCol={{
                span: 16,
            }}
            style={{
                maxWidth: 600,
                
            }}
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Account Name"
                name="account_name"
                rules={[
                    {
                        required: true,
                        message: 'Please enter your account name!',
                    },
                ]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Email"
                name="email"
                rules={[
                    {
                        required: true,
                        message: 'Please input your email!',
                    },
                ]}
            >
                <Input type='email' />
            </Form.Item>

            <Form.Item
                label="Alternate Phone Number"
                name="alternate_phone_number"
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Gender"
                name="gender"
                rules={[
                    {
                        required: true,
                        message: 'Please input your gender!',
                    },
                ]}
            >
                <Radio.Group>
                    <Radio value="male"> Male </Radio>
                    <Radio value="female"> Female </Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label="Date of Birth"
                name="dob"
                rules={[
                    {
                        required: true,
                        message: 'Please input your date of birth!',
                    },
                ]}
            >
               <DatePicker />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    span: 16,
                }}
            >
                <Button type="primary" htmlType="submit" style={{    height: "41px", padding:" 5px 13px", fontWeight: "600",textTransform: "uppercase", background: "#034ea2",
    borderRadius: "3px"}}>
                    Create Account
                </Button>
                
            </Form.Item>
        </Form>
    )
}

export default CreateAccount