import React, {useState} from 'react'
import { Input, Button, Label } from 'styles/pages/LandingPage'
import usePost from 'hooks/usePost';
import { REGISTER_SEND_OTP } from 'constants/api';
import { toast } from 'react-toastify';
import useMetaData from 'context/metaData'

const RegisterNumber = ({next}) => {
    const {registerPhoneNumber, setRegisterPhoneNumber} = useMetaData();
    const [disableOtp, setDisableOtp] = useState(true);
    const {mutateAsync: otpSent} = usePost();

    const handleChange = (e) => {
        let regex = /^\d+$/;
    
        if ((regex.test(e.target.value) || e.target.value == '') && e.target.value.length <= 13) {
          setRegisterPhoneNumber(e.target.value);
          if (registerPhoneNumber.length > 8) {
            setDisableOtp(false);
          } else {
            setDisableOtp(true);
          }
        }
      };
    
      const handleInputAutoFill = (e) => {
        setTimeout(() => {
          let regex = /^\d+$/;
          
          if ((regex.test(e.target.value) || e.target.value == '') && e.target.value.length <= 13) {
            setRegisterPhoneNumber(e.target.value);
          if (e.target.name == "registerPhoneNumber" && e.target.value.length > 8) {
            setDisableOtp(false);
          } else {
            setDisableOtp(true);
          }
        }
        }, 0);
      };

      const sendOtp = () => {
        const payload = {
          username: registerPhoneNumber,
        };
        otpSent({ url: REGISTER_SEND_OTP, payload }).then((res) => {
            next();
            toast.success(res?.response?.message);
        }).catch((error) => {
          console.error(error)
        });
      };
    return (
        <>
            <Label>Mobile Number</Label>
            <Input
                type="text"
                name="registerPhoneNumber"
                value={registerPhoneNumber}
                onChange={handleChange}
                onInput={handleInputAutoFill}
            />
            <Button disabled={disableOtp} onClick={sendOtp} type="submit">
                Send OTP
            </Button>
        </>
    )
}

export default RegisterNumber