import React, { createContext, useContext, useState } from 'react'

const contextDefaultValues = {
    registerPhoneNumber: '',
    setRegisterPhoneNumber: (data) => data,
    registerUserToken: '',
    setregisterUserToken: (data) => data,
    custmerDetails: {},
    setCustmerDetails: (data) => data
}

const MetaDataContext = createContext(contextDefaultValues)

export const MetaDataProvider = ({ children }) => {
    const [registerPhoneNumber, setRegisterPhoneNumber] = useState(contextDefaultValues.registerPhoneNumber)
    const [registerUserToken, setregisterUserToken] = useState(contextDefaultValues.registerUserToken);
    const [custmerDetails, setCustmerDetails] = useState(contextDefaultValues.custmerDetails);

    return (
        <MetaDataContext.Provider
            value={{
                registerPhoneNumber,
                setRegisterPhoneNumber,
                registerUserToken,
                setregisterUserToken,
                custmerDetails,
                setCustmerDetails
            }}
        >
            {children}
        </MetaDataContext.Provider>
    )
}

export default () => useContext(MetaDataContext)
