import React from "react";
import { useNavigate } from "react-router-dom";
import {
  DashboardOutlined,
  UserOutlined,
  // AreaChartOutlined,
  // PicRightOutlined,
  LogoutOutlined,
  BarsOutlined
} from "@ant-design/icons";
import {toast} from "react-toastify"
import { ProfileSection, SidebarSection, MenuIcon } from "styles/components/Sidebar";
import { Avatar, Layout, Menu } from "antd";
import usePost from "hooks/usePost";
import useMetaData from 'context/metaData';
import { LOGOUT } from "constants/api";
const { Sider } = Layout;

const Sidebar = ({ userDetails, collapsed, setCollapsed, selectedKey, setSelectedKey }) => {
  const {setCustmerDetails} = useMetaData();
  const {mutateAsync: logoutProfile} = usePost()

  const logout = () => {
    const api_token = localStorage.getItem('token');
    logoutProfile({
      url: `${LOGOUT}${api_token}`,
      payload: {},
      token: true
    }).then((res) => {
      toast.success(res?.response?.message)
      localStorage.clear();
      setCustmerDetails({})
      // navigate(LoginRoute.path);
    }).catch((err) => {
      console.error(err)
    })
  }
  const menuItems = [
    {
      key: "1",
      icon: <DashboardOutlined style={{color: selectedKey == "1" ? 'rgb(3, 78, 162)' : '#fff'}} />,
      label: "Dashboard",
      onClick: () => redirectRoute("/"),
    },
    // {
    //   key: "2",
    //   icon: <PicRightOutlined style={{color: selectedKey == "2" ? 'rgb(3, 78, 162)' : '#fff'}} />,
    //   label: "Devices",
    //   onClick: () => redirectRoute('/devices')
    // },
    // {
    //   key: "3",
    //   icon: <AreaChartOutlined style={{color: selectedKey == "3" ? 'rgb(3, 78, 162)' : '#fff'}} />,
    //   label: "Reports",
    //   onClick: () => redirectRoute('/reports')
    // },
    {
      key: "4",
      icon: <UserOutlined style={{color: selectedKey == "4" ? 'rgb(3, 78, 162)' : '#fff'}} />,
      label: "Profile",
      onClick: () => redirectRoute('/profile')
    },
    {
      key: "5",
      icon: <LogoutOutlined style={{color: '#fff'}}/>,
      label: "Sign Out",
      onClick: () => logout()
    },
  ]
  const navigate = useNavigate();

  const redirectRoute = (route) => {
    navigate(route);
    setCollapsed(true);
  };
  const setActiveRoute = (e) => {
    const key = e.key;
    localStorage.setItem('selectedKey', key);
    setSelectedKey(key);
  }
  return (
    <SidebarSection collapsed={`${collapsed}`}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        trigger={ window.innerWidth <= 768 ? <MenuIcon ><BarsOutlined style={{fontSize: '20px', color: window.innerWidth <= 768  && window.innerWidth > 480 ? '#fff' : '#000'}} /> JioThings</MenuIcon> : ''}
      >
        <ProfileSection>
          <div className="Sidebar-close">
            <div>
              <Avatar icon={<UserOutlined />} />
              
            </div>
            <h2>{userDetails?.account?.name || ""}</h2>
            <p>{userDetails?.phone_number || ""}</p>
          </div>
        </ProfileSection>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[JSON.stringify(selectedKey) ?? '1']}
          onClick={setActiveRoute}
          activeKey="true"
        >
          {menuItems.map((menuItem) => {
            if(menuItem.label == 'Reports' && userDetails?.account?.type == "jttms_operations"){
              return (
                <Menu.Item key={menuItem.key} onClick={() => menuItem.onClick()} icon={menuItem.icon}>{menuItem.label}</Menu.Item>
              )
            } else {
              if(menuItem.label != 'Reports') {
                return(
                  <Menu.Item key={menuItem.key} onClick={() => menuItem.onClick()} icon={menuItem.icon}>{menuItem.label}</Menu.Item>
                )
              }
            }
          })}
        </Menu>         
      </Sider>
    </SidebarSection>
  );
};

export default Sidebar;
