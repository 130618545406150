import { styled, css } from "styled-components";



export const DeviceTabs = styled.div`
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0c5273;
    font-weight: 600;
  }
  .ant-tabs .ant-tabs-ink-bar {
    background: #283587;
  }
  .ant-tabs .ant-tabs-tab:hover {
    color: #283587;
    font-weight: 600;
  }
`;

export const ListSection = styled.li`
  border: 1px solid #0c5273;
  border-radius: 13px;
  list-style: none;
  padding: 10px;
  margin-bottom: 10px;
  width: 500px;
  max-width: 100%;
  box-shadow: 6px 9px 11px #ddd;
  background: white;
  .ant-row {
    justify-content: space-between;
    flex-flow: unset;
  }

  @media (max-width: 768px) {
    border-radius: 5px;
    box-shadow: 2px 1px 20px 0px #ddd;
    position: relative;
    .input-type-checkbox {
      position: absolute;
      right: -18px;
      padding-left: 0;
    }
  }
`;
export const BoxLayout = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* gap: 30px; */
  max-width: 750px;
  /* justify-content: space-between; */
  align-items: center;
  /* margin: 0 auto; */
  margin-top: 20px;
`;
export const InputText = styled.input`
  color: #283587;
  font-size: 15px;
  font-weight: 500;
  margin: 10px 0 4px;
  border-radius: 10px;
  border: 1px solid #5a50c1;
  text-align: left;
  padding: 10px 3px 8px 8px;
  width: 214px;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;
export const BoxSection = styled.div`
  /* background-color: #5a50c1; */
  /* color: #fff; */
  border: 1px solid #283587;
  /* border-radius: 5px; */
  padding: 10px;
  /* box-shadow: 1px 7px 18px #5a50c1; */
  width: 331px;
  min-height: 95px;
  .ant-row {
    justify-content: space-between;
  }
  span {
    color: red;
    font-size: 10px;
    padding-left: 3px;
    font-weight: 700;
  }
`;

export const TimeStamp = styled.div`
  text-align: right;
  padding-top: 10px;
  display: flex;
  justify-content: end;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) =>
      props.active == "true" ? "#35ab02" : "#FF0000"};
    margin-right: 5px;
    margin-top: 5px;
  }
`;
export const DeviceLocTimeStamp = styled.div`
  text-align: right;
  padding-top: 10px;
  display: flex;
  justify-content: end;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #283587;
    margin-right: 5px;
    margin-top: 5px;
  }
`;
export const Info = styled.div`
  font-weight: 600;
  font-size: 16px;
  padding: 0 5px;
  @media (max-width: 768px) {
    white-space: nowrap;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const Infosubheading = styled.div`
  font-weight: 600;
  font-size: 12px;
  padding: 0 5px;
  /* white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis; */
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #283587;
  margin-bottom: 30px;
  text-align: center;
`;
export const PaginationLayout = styled.div`
  text-align: center;
`;
export const ListLayout = styled.ul`
  padding-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const DeleteBtn = styled.button`
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  cursor: pointer;
  height: 36px;
  border: 0px solid;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 18px;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    `}
`;
export const AddBtn = styled.button`
  color: #fff;
  background-color: #0c5273;
  border-color: #5a50c1;
  cursor: pointer;
  height: 36px;
  border: 0px solid;
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 18px;
`;
export const SaveBtn = styled.button`
  color: #fff;
  background: #283587;
  cursor: pointer;
  border: 0px solid;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;
export const MapDiv = styled.div`
  height: 400px;
  overflow: hidden;
  @media (max-width: 480px) {
    height: 450px;
  }
`;
export const InfoSection = styled.div`
  padding-top: 30px;
  .ant-table-wrapper {
    overflow: auto;
  }
`;
export const AddDevice = styled.div`
  text-align: right;
`;
export const DateTime = styled.div``;
export const RefreshBtn = styled.div`
  text-align: right;
  padding-top: 20px;
  svg {
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  &.no-animation svg {
    animation: none;
  }
`;
