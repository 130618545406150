import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { PieChartSection } from "styles/pages/Report";

const ReportPieChart = ({ reportData, type = "pie", legendHeading }) => {
  const [userState, setUserState] = useState({});
  const [currentDim, setCurrentDim] = useState({
    width: "100%",
    height: 450,
  });

  useEffect(() => {
    if (window.innerWidth < 480 && window.innerWidth > 360) {
      setCurrentDim({
        height: 450,
        width: "100%",
      });
    } else if (window.innerWidth <= 360) {
      setCurrentDim({
        height: 450,
        width: "100%",
      });
    } else {
      setCurrentDim({
        height: 450,
        width: "100%",
      });
    }
  }, [window.innerWidth]);

  useEffect(() => {
    if (Object.keys(reportData).length > 0) {
      if (Object.keys(reportData).includes("total_count")) {
        delete reportData["total_count"];
      }
      const labelKeys = Object.keys(reportData).map((key) => {
        let updatedKey = key.charAt(0).toUpperCase() + key.slice(1);
        return `${updatedKey.replaceAll("_", " ")} - ${reportData[key]}`;
      });
      const seriesValue = [].concat(Object.values(reportData));
      const labelsValue = [].concat(labelKeys);
      setUserState({
        series: seriesValue,
        options: {
          title: {
            text: legendHeading,
          },
          chart: {
            type: "donut",
            width: 500,
          },
          legend: {
            position: "bottom",
          },
          labels: labelsValue,
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: "100%",
                },
              },
            },
          ],
        },
      });
    }
  }, [reportData]);

  return (
    <>
      <PieChartSection id="chart">
        {!!Object.keys(userState).length && (
          <ReactApexChart
            options={userState.options}
            series={userState.series}
            type={type}
            height={currentDim.height}
            width={currentDim.width}
          />
        )}
      </PieChartSection>
    </>
  );
};

export default ReportPieChart;
