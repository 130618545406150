import React, { useState, useEffect } from 'react'
import { useLocation, Link } from "react-router-dom";
import { Col, Row, Pagination, Select } from "antd";
import moment from 'moment';
import {
  Title,
  MapDiv,
  InfoSection,
  ListSection,
  Infosubheading,
  DeviceLocTimeStamp,
  DateTime
} from "styles/pages/Devices";
import MapLayout from "components/MapLayout";
import MarkerIcon from 'assets/images/marker.png'
import { GET_DEVICE_LOCATION } from "constants/api";
import useGet from 'hooks/useGet';

const TrackedLocation = ({ setItemsPath, setSelectedKey }) => {
  const location = useLocation();
  const { mutateAsync: getDeviceDetail } = useGet();
  const [deviceLocations, setDeviceLocations] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLocation, setTotalLocation] = useState(0);
  const [trackingMode, setTrackingMode] = useState(2);
  const [days, setDays] = useState(30);
  const deviceId = location.pathname.split("last-location/")[1];

  useEffect(() => {
    const path = [
      {
        title: <Link to="/dashboard">Dashboard</Link>
      },
      {
        title: <Link to="/device">Devices</Link>
      },
      {
        title: <Link to={`/device/${deviceId}`}>Device Info</Link>
      },
      {
        title: <Link to={`/last-location/${deviceId}`}>Device Locations</Link>
      }
    ]
    setItemsPath(path)
    setSelectedKey(2)
  }, []);

  useEffect(() => {
    onPageChange(currentPage)
  }, [trackingMode, days]);

  useEffect(() => {
    if (deviceLocations.length > 0) {
      const data = deviceLocations.map((res, index) => {
        return {
          key: index,
          accuracy: res.accuracy,
          address: res.location_address,
          updated: moment(res?.serverTime).format('DD/MM/YYYY HH:mm:ss A'),
        }
      })
      setTableData(data)
    }
  }, [deviceLocations]);

  const handleTrackingStatus = (e) => {
    setTrackingMode(e)
  }

  const handleDaysChange = (e) => {
    setDays(e)
  }

  const onPageChange = (pageNum) => {
    setCurrentPage(pageNum)
    const account_number = localStorage.getItem("account_number");
    const location_url = `${GET_DEVICE_LOCATION}${deviceId}/?resource=jio:aaa:${account_number}:accounts:&action=get_location&days=${days}&location_type=${trackingMode}&page=${pageNum}`;
    getDeviceDetail({
      url: location_url,
      token: true
    }).then((res) => {
      setDeviceLocations(res.data);
      setTotalLocation(res?.meta?.count);
    }).catch((error) => {
      console.error(error)
    });
  }

  return (
    <>
      <Title>Last Locations</Title>
      <div className='' style={{display: "flex",gap: "50px", marginBottom: "23px"}}>
      
          <Select
            defaultValue={2}
            style={{
              maxWidth: 160,
            }}
            onChange={handleTrackingStatus}
            options={[
              {
                value: 2,
                label: 'GPS Tracking',
              },
              // {
              //   value: 1,
              //   label: 'Sim Tracking',
              //   disabled: true,
              // },
            ]}
          />
          <Select
            defaultValue={30}
            style={{
              maxWidth: 160,
            }}
            onChange={handleDaysChange}
            options={[
              {
                value: 7,
                label: 'Week Ago',
              },
              {
                value: 30,
                label: 'Month Ago',
              },
            ]}
          />
        </div>
      {!!deviceLocations.length && (
        <MapDiv id="map">
          <MapLayout deviceLocations={deviceLocations} />
        </MapDiv>
      )}

      <InfoSection>

        {!!tableData.length && tableData.map((data, index) => (
          <ListSection key={index}>
            <Row>
              <Col >
                <Row>
                  <Col>
                    <Infosubheading>
                      <img src={MarkerIcon} style={{
                        width: "9px",
                        marginRight: "5px",
                      }} />
                      {data.address}
                    </Infosubheading>
                    <div>
                      <Infosubheading >Accuracy: {data?.accuracy}</Infosubheading>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <DeviceLocTimeStamp>
              <div className="circle"></div>
              <DateTime>
                {data?.updated}
              </DateTime>
            </DeviceLocTimeStamp>
          </ListSection>
        ))}
        <Pagination
          defaultCurrent={1}
          current={currentPage}
          total={totalLocation}
          pageSize={20}
          onChange={onPageChange}
        />
      </InfoSection>
    </>
  )
}

export default TrackedLocation