import styled, { keyframes, createGlobalStyle } from "styled-components";

export const jump = keyframes`
  from{
    transform: translateY(0)
  }
  to{
    transform: translateY(-3px)
  }
`;

export const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  body, html, #root {
    height: 100%;
    font-family: -apple-system, Ubuntu , BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;;
  }
`;

export const Section = styled.section`
  background-color: #034ea2;
  min-height: 100vh;
  display: flex;
  align-items: center;
  @media (max-width:576px) {
    padding: 10px;
    
  }
`;
export const Wrapper = styled.section`
  align-items: center;
  height: 100%;
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  border-radius: 10px;
  /* max-width: 339px; */
  max-width: 516px;
  padding: 31px 39px;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 5px #ddd;
  :where(.css-dev-only-do-not-override-17a39f8).ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover,.ant-btn-primary {
    background-color: #034ea2;
}
  .ant-form.ant-form-horizontal {
    padding-top: 20px;
  }
  @media (max-width: 576px) {
    max-height: 500px;
    padding: 31px 20px;
    overflow: auto;
    .ant-form-item {
      .ant-row.ant-form-item-row {
        justify-content: flex-end;
      }
    }
    .ant-form.ant-form-horizontal {
      padding-top: 0px;
    }
  }
  @media (min-width: 562px) {
    .ant-steps-item-content {
      display: none;
    }
  }
  h2 {
    color: #034ea2;
  }
  input {
    width: 100%;
  }
  label {
    width: 100%;
    color: #034ea2;
  }
`;

export const Form = styled.form`
  margin: 0 auto;
  width: 100%;
  max-width: 414px;
  padding: 1.3rem;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Input = styled.input`
  max-width: 100%;
  padding: 11px 13px;
  background: #f9f9fa;
  color: #034ea2;
  margin-bottom: 0.9rem;
  border-radius: 4px;
  outline: 0;
  border: 1px solid rgba(245, 245, 245, 0.7);
  font-size: 14px;
  transition: all 0.3s ease-out;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.1);
  :focus,
  :hover {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.15), 0 1px 5px rgba(0, 0, 0, 0.1);
  }
`;

export const Button = styled.button`
  max-width: 100%;
  padding: 11px 13px;
  color: rgb(253, 249, 243);
  font-weight: 600;
  text-transform: uppercase;
  background: #034ea2;
  border: none;
  border-radius: 3px;
  outline: 0;
  cursor: pointer;
  margin-top: 0.6rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-out;
  :hover {
    background: rgb(200, 50, 70);
    animation: ${jump} 0.2s ease-out forwards;
  }
  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 600;
  color: #fff;
`;

export const Title = styled.h2`
  font-weight: 700;
  font-size: 28px;
  color: #fff;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

export const WrapDiv = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  p {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    padding-top: 10px;
  }
`;

export const TabWrap = styled.div`
  .ant-tabs-nav-wrap {
    justify-content: center;
  }
  .ant-tabs-tab-btn {
    color: #d7d7d7;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    .ant-tabs-tab-btn{
      color: #034ea2;
    }
    /* border-bottom: 4px solid #034ea2; */
}
.ant-tabs .ant-tabs-ink-bar {
    background: #034ea2;
    height: 4px;
}
`;

export const SendOtp = styled.div`
  text-align: right;
  margin-left: auto;
`;

export const OtpButton = styled.button`
  width: 75px;
  text-align: center;
  color: #000;
  font-weight: 600;
  border: none;
  border-radius: 20px;
  background: #d5c9cb;
  cursor: pointer;
  &[disabled] {
    opacity: 0.2;
    pointer-events: none;
    cursor: not-allowed;
  }
`;
