import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal, Select } from 'antd';
import { toast } from 'react-toastify'
import OtpInput from 'react-otp-input';
import usePost from "hooks/usePost";
import useGet from "hooks/useGet";
import { CREATE_NEW_DEVICE, GET_DEVICE_TYPE, SEND_OTP_DEVICE, GET_DEVICE_MODEL } from "constants/api";
import DeviceFields from "./NewDeviceFields.json"

const AddNewDevice = ({ open, setOpen, onPageChange }) => {
    const [form] = Form.useForm();
    const { mutateAsync: createDevice } = usePost();
    const { mutateAsync: getDeviceList, mutateAsync: getDeviceModal } = useGet();
    const [loading, setLoading] = useState(false);
    const [sendOtpLoading, setSendOtpLoading] = useState(false);
    const [deviceTypes, setDeviceTypes] = useState([]);
    const [deviceTagType, setDeviceTagType] = useState('');
    const [otpInputDisabled, setOtpInputDisabled] = useState(true);
    const [deviceModels, setDeviceModels] = useState([]);
    const account_number = localStorage.getItem('account_number');

    useEffect(() => {
        const URL = `${GET_DEVICE_TYPE}${account_number}:device:&action=get_device_static_info&info=device_type`
        getDeviceList({
            url: URL,
            token: true,
        }).then((res) => {
            setDeviceTypes(res?.response?.payload)
        }).catch((error) => {
            console.error(error)
        });
    }, []);

    const handleOk = async () => {
        if (deviceTagType == 'JioPhone Lite' || deviceTagType == 'JioPhone') {
            const otp = form.getFieldValue('otp');
            form.setFieldValue('rsn', '')
            form.setFieldValue('otp', otp)
        } else {
            form.setFieldValue('jiophone_lite_number', '')
        }
        const formValues = form.getFieldValue()
        const payload = {
            ...formValues,
            "action": "create_device",
            "is_online": true,
            "resource": `jio:jttms:${account_number}:device:`,
            "tag": "Laptop",
            "otp_type": "create_jiophone"
        }
        if (deviceTagType != 'JioPhone Lite' && deviceTagType != 'JioPhone') {
            delete payload.otp_type
        }
        setLoading(true);
        await createDevice({
            url: CREATE_NEW_DEVICE,
            token: true,
            payload
        }).then((res) => {
            setLoading(false)
            setOpen(false)
            toast.success(res?.response?.message)
            setDeviceTagType('')
            form.resetFields()
            onPageChange(1, 20)
        }).catch((error) => {
            setLoading(false)
            // form.resetFields()
            // setOpen(false)
            console.error(error)
        });
    };

    const handleCancel = () => {
        setDeviceTagType('')
        form.resetFields();
        setOpen(false);
    };

    const values = Form.useWatch([], form);
    const [submitBtn, setSubmitBtn] = useState(true);

    useEffect(() => {
        form.validateFields({
            validateOnly: true,
        }).then(() => {
            setSubmitBtn(false);
        },
            () => {
                setSubmitBtn(true);
            },)
    }, [values]);

    const handleDeviceTypeChange = (value, option) => {
        setDeviceTagType(option.children)
        form.setFieldValue('device_type_id', value)
        getDeviceModal({
            url: `${GET_DEVICE_MODEL}${account_number}:device:&action=get_device_model_info&info=device_model&device_type=${option?.name}`,
            token: true
        }).then((res) => {
            setDeviceModels(res?.response?.payload)
        }).catch((error) => {
            console.error(error)
        });
    }

    const handleModelChange = (value) => {
        form.setFieldValue('device_model', value)
    }
    const handleRelationChange = (value) => {
        form.setFieldValue('relation', value)
    }

    const handleSendOtp = () => {
        setSendOtpLoading(true)
        const mobileNumber = form.getFieldValue('jiophone_lite_number')
        const payload = {
            "msisdn": mobileNumber,
            "otp_type": "create_jiophone",
            "resource": `jio:aaa:${account_number}:accounts:`,
            "action": "send_otp"
        }
        createDevice({
            url: SEND_OTP_DEVICE,
            token: true,
            payload
        }).then((res) => {
            toast.success(res?.response?.message)
            setSendOtpLoading(false)
            setOtpInputDisabled(false)
        }).catch((err) => {
            console.error(err)
        })
    }

    const validateNumber = (rule, value, callback) => {
        const numberPattern = /^-?\d+(\.\d+)?$/;
        if (value && !numberPattern.test(value)) {
            callback('Please enter a valid number.');
        } else {
            callback();
        }
    };

    return (
        <Modal
            open={open}
            title="Create Device"
            onOk={handleOk}
            onCancel={handleCancel}
            maskClosable={false}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Back
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                    disabled={submitBtn}
                >
                    Submit
                </Button>,
            ]}
        >
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter name' }]}>
                    <Input placeholder="Please enter name" />
                </Form.Item>
                <Form.Item name="device_type_id" label="Device Type" rules={[{ required: true, message: 'Please enter device type' }]}>
                    <Select onSelect={handleDeviceTypeChange}>
                        {deviceTypes.length > 0 && deviceTypes.map((deviceType, index) => {
                            if (deviceType.is_active) {
                                return (<Select.Option name={deviceType.name} key={index} value={deviceType.id}>{deviceType.ui_name}</Select.Option>)
                            }
                        }
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name="device_model" label="Device Model" rules={[{ required: true, message: 'Please enter device model' }]}>
                    <Select disabled={!(deviceModels.length > 0)} onSelect={handleModelChange}>
                        {deviceModels.length > 0 && deviceModels.map((model, index) =>
                            <Select.Option key={index} value={model.device_model}>{model.ui_name}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
                {deviceTagType != 'JioPhone Lite' && deviceTagType != 'JioPhone' && (
                    <Form.Item name="rsn" label="RSN" rules={[{ required: true, message: 'Please enter rsn' }]}>
                        <Input placeholder="Please enter rsn" />
                    </Form.Item>
                )}
                {(deviceTagType == 'JioPhone Lite' || deviceTagType == 'JioPhone') && (
                    <>
                        <Form.Item name="relation" label="Relation" rules={[{ required: true, message: 'Please enter your relationship' }]}>
                            <Select onSelect={handleRelationChange}>
                                {DeviceFields['relation'] && DeviceFields['relation'].length > 0 && DeviceFields['relation'].map((rel, index) =>
                                    <Select.Option key={index} value={rel.value}>{rel.ui_name}</Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                        <Form.Item name="jiophone_lite_number" label="Jio Phone Number" rules={[{ required: true, message: 'Please enter jio number' }, { validator: validateNumber }]}>
                            <Input placeholder="Please enter your jio number" />
                        </Form.Item>
                        <Form.Item name="imei" label="IMEI Number" rules={[{ required: true, message: 'Please enter imei number' }, { validator: validateNumber }]}>
                            <Input placeholder="Please enter your imei number" />
                        </Form.Item>
                        <Button
                            disabled={!form.getFieldValue('jiophone_lite_number') || form.getFieldValue('jiophone_lite_number').length < 8}
                            onClick={handleSendOtp} style={{ marginTop: '-9px', marginBottom: '10px' }} type="primary" loading={sendOtpLoading}>
                            Send OTP
                        </Button>
                        <Form.Item
                            name="otp"
                            label="Enter OTP"
                            className="center-error-message"
                            disabled={true}
                            rules={[{ required: true, message: 'Please enter OTP' }]}
                        >
                            <OtpInput inputStyle="otp-blocks" renderSeparator={<span>-</span>} inputType="numeric" numInputs={6} renderInput={(props) => <input disabled={otpInputDisabled} {...props} />} />
                        </Form.Item>
                    </>
                )}
            </Form>
        </Modal>
    );
};

export default AddNewDevice;
